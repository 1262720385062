<div class="container">
  <mat-card class="checkout-card mt-3">
    <mat-card-header>
      <h3 class="text-center" id="checkoutTop">Complete Your Order</h3>
    </mat-card-header>
    <mat-card-content>

      <!-- <pre *ngFor="let item of orderService.cartItems"> {{ item | json}}</pre> -->
      <section *ngIf="orderService.cartLength > 0 else noItems">
        <div *ngFor="let item of orderService.cartItems" class="my-2">
          <div class="row">

            <span class="flexContainer">{{item.quantity}} x {{item.name}} </span>

            <div>
              <span class="text-right"> {{item.price | currency}}</span>

            </div>
          </div>
          <div class="row flex-start">
            <div class="modifiers">

              <!-- Start Modifiers -->
              <!-- HARD CODED ITEMS -->
              <p class="indent"><b>{{item.size}}</b></p>
              <!-- Dynamic Modifiers -->
              <p *ngFor="let mod of orderService.modifiers" class="indent" class="m-0">
                <b *ngIf="item[mod.name]">
                  <span *ngIf="mod.type == 'multi-dropdown'">Add: </span><span
                    *ngIf="mod.type == 'incrementor'">{{mod.name}}:
                  </span> <span *ngIf="mod.type != 'boolean'">{{ item[mod.name] }}</span><span
                    *ngIf="mod.type == 'boolean'">{{ mod.displayName }}</span></b>
              </p>
              <p class="m-0" *ngIf="item.couponItem == true">Coupon Item</p>
              <p class="indent" *ngIf="item.instructions"><b>Instructions: {{item.instructions}}</b></p>
            </div>
            <div class="removeButton" *ngIf="!item.couponItem == true">
              <button class="mx-1 my-1" mat-stroked-button color="warn" (click)="removeItem(item)">Remove </button>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>




        <div>
          <form [formGroup]="orderService.orderObject">
            <!-- <h5>Need utensils? Let us know here: </h5> -->

            <mat-form-field class="w-100 mt-1" appearance="fill" required="true">
              <mat-label>Fulfillment Instructions</mat-label>
              <textarea formControlName="orderInstructions" type="text" matInput
                placeholder="Door code, etc. Please do not place food notes here."></textarea>
              <mat-hint>Please do not place food notes here. Infor in this section will not be sent into kitchen.
              </mat-hint>
            </mat-form-field>
            <div style="margin-top: 1rem;">
              <mat-checkbox class="checkBox" formControlName="utensil"></mat-checkbox>
              <mat-label>Include utensils?</mat-label>
            </div>
          </form>
          <div class="space-evenly flex-wrap">
            <div class="w-50-left tip-section l-w-100">
              <div class="toggle mb-2">
                <p class="upperHint">Please leave a tip.</p>
                <mat-button-toggle-group #tip="matButtonToggleGroup" aria-label="Tip Amount"
                  (change)="tipChange(tip.value)" [value]="orderService.tipPercent" name="tipValue"
                  appearance="standard">
                  <mat-button-toggle [value]="0.0">0%</mat-button-toggle>
                  <mat-button-toggle [value]="0.05">5%</mat-button-toggle>
                  <mat-button-toggle [value]="0.10">10%</mat-button-toggle>
                  <mat-button-toggle [value]="0.15">15%</mat-button-toggle>
                  <mat-button-toggle [value]="0.2">20%</mat-button-toggle>
                  <mat-button-toggle [value]="0.3">30%</mat-button-toggle>
                  <mat-button-toggle value="other">Other</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div style="text-align: right; width: min-content;" class="w-50-right">
              <div class="total">
                <p>SubTotal: </p>
                <p> {{orderService.orderObject.get('subTotal').value | currency}}</p>
                <p *ngIf="orderService.orderObject.get('discount').value != 0">Discount: </p>
                <p *ngIf="orderService.orderObject.get('discount').value != 0">
                  <b>{{orderService.orderObject.get('discount').value | currency}}</b>
                </p>
                <p>Tax: </p>
                <p> {{orderService.orderObject.get('tax').value | currency}}</p>
                <p>Convenience Fee: </p>
                <p>{{orderService.orderObject.get('convenienceFee').value | currency}}</p>
                <p *ngIf="orderService.orderObject.get('orderType').value == 'Doordash'">Doordash Fee: </p>
                <p *ngIf="orderService.orderObject.get('orderType').value == 'Doordash'">
                  {{ orderService.restaurant.doordashFee| currency}}
                </p>
              </div>
              <div *ngIf='orderService.orderObject.get("additionalFees").value.length!=0' class="addFees">
                <ng-container *ngFor="let additionalFee of orderService.orderObject.get('additionalFees').value">
                  <p *ngIf='additionalFee.orderType == orderService.orderObject.get("orderType").value'>
                    {{additionalFee.displayName}}:</p>
                  <p *ngIf='additionalFee.orderType == orderService.orderObject.get("orderType").value'>
                    {{additionalFee.value | currency}}
                  </p>
                </ng-container>
              </div>
              <div class="total">
                <p>Tip: </p>
                <p>{{orderService.orderObject.get('tipAmount').value | currency}}</p>
                <p><b>Total: </b></p>
                <p><b>{{orderService.orderObject.get('total').value | currency}}</b></p>
              </div>


            </div>
          </div>

          <div class="space-evenly" *ngIf="tip.value == 'other' ">
            <mat-form-field class="col-3 pb-0">
              <span matSuffix>% &nbsp;</span>
              <input min="0" type="number" matInput placeholder="Tip" [(ngModel)]="otherTipAmount">
            </mat-form-field>
            <button color="accent" mat-raised-button class="btnHeight col-1 align-content-center"
              (click)="tipChange(tip.value)">OK</button>
          </div>
          <hr>
        </div>
        <div class="couponContainer">
          <div *ngIf="orderService.appliedCoupons.length > 0 else noCoupons">
            <div *ngFor="let coupon of orderService.appliedCoupons.controls let index = i">
              <div class="coupon">
                <h3 class="text-center">{{coupon.value.title}}</h3>
                <div class="flex-wrap space-between">
                  <span class="m-0 w-60">{{coupon.value.description}}</span>

                  <button class="" mat-stroked-button color="warn" (click)="orderService.removeCoupon(coupon)">Remove
                  </button>

                </div>
                <p *ngIf="coupon.errors?.minSubtotal" class="text-danger">{{coupon.errors.minSubtotal}}</p>
                <p *ngIf="coupon.errors?.orderType" class="text-danger">{{coupon.errors.orderType}}</p>
                <p *ngIf="coupon.errors?.mixable" class="text-danger">{{coupon.errors.mixable}}</p>
              </div>

            </div>
          </div>
          <ng-template #noCoupons>
            <div *ngFor="let coupon of orderService.freeItemsCoupons">
              <div *ngIf="coupon.active" class="coupon">
                <h3 class="text-center">{{coupon.title}}</h3>
                <div class="space-evenly">
                  <p class="m-0">{{coupon.description}}</p>

                  <button mat-raised-button class="" color="accent"
                    (click)="orderService.addCoupon(coupon)">Add</button>

                </div>
                <div class="space-evenly">
                  <mat-form-field *ngIf="coupon.type == 'freeItem' && coupon.freeItems.length > 1" class="">
                    <mat-label>Choose free item: </mat-label>
                    <mat-select (selectionChange)="orderService.updateCouponSelection($event, coupon)">
                      <mat-option *ngFor="let item of coupon.freeItems" [value]="item">
                        {{item}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

            </div>
          </ng-template>
        </div>
        <div class="mt-4 text-center">
          <div class="space-between simple-border">
            <div class="space-between m-2">
              <p class="m-1">Order Type:</p>
              <!-- <button class="m-0 address" (click)="openOrderDetails()" mat-raised-button
                color="accent">{{orderService.orderObject.get('orderType').value}}</button> -->
              <div class="btn-group" role="group" aria-label="Basic example">
                <button [color]="orderService.orderObject.get('orderType').value =='Pickup'? 'accent':''"
                  mat-raised-button class="m-0 address" (click)="openOrderDetails('Pickup')">Pickup</button>
                <button [color]="orderService.orderObject.get('orderType').value =='Delivery'? 'accent':''"
                  *ngIf='orderService.restaurant.takesDelivery' mat-raised-button class="m-0 address"
                  (click)="openOrderDetails('Delivery')">Delivery</button>
                <button [color]="orderService.orderObject.get('orderType').value =='Doordash'? 'accent':''"
                  *ngIf='orderService.restaurant.takesDoordash' mat-raised-button class="m-0 address"
                  (click)="openOrderDetails('Doordash')">Doordash</button>
              </div>
            </div>
            <div class="space-between m-1">
              <p class="m-1">Time: </p>
              <button mat-raised-button color="accent" class="m-0"
                *ngIf="orderService.orderObject.get('isFutureOrder').value == false" (click)="openTimeDetails()">Now
              </button>
              <button mat-raised-button color="accent" class="m-0"
                *ngIf="orderService.orderObject.get('isFutureOrder').value == true" (click)="openTimeDetails()">
                {{orderService.orderObject.get('futureOrderDateTime').value.format('LLLL')}}
              </button>
            </div>
            <p *ngIf="orderService.orderObject.get('orderType').value == 'Delivery'">Deliver To:
              {{orderService.orderObject.get('deliveryAddress').value}},
              {{orderService.orderObject.get('aptNum').value}}</p>

            <p *ngIf="orderService.orderObject.get('orderType').value == 'Pickup'" style="font-weight: bold;"
              class="text-center mt-2">Estimated Pickup Wait
              Time: {{this.orderService.restaurant.pickupTime}}</p>
            <p *ngIf="orderService.orderObject.get('orderType').value == 'Delivery'" style="font-weight: bold;"
              class="text-center mt-2">Estimated
              Delivery Wait
              Time: {{this.orderService.restaurant.deliveryTime}}</p>
          </div>

          <p class="edit text-danger m-1" (click)="openTimeDetails()"
            *ngIf="orderService.orderObject.errors?.invalidOrderTime">You have not selected
            a valid order time
          </p>
          <p class="edit text-danger m-1" (click)="openOrderDetails(orderService.orderObject.get('orderType').value)"
            *ngIf="orderService.orderObject.get('deliveryAddress').invalid"> You have not input a valid delivery
            address.
          </p>
          <mat-divider></mat-divider>
          <p class="text-danger txt-left p-16" *ngIf="orderService.orderObject.get('items').errors?.lunchItemError">
            ERROR:
            You have lunch items in your cart. Please remove the items or change the order time. </p>
          <p class="text-danger txt-left p-16" *ngIf="orderService.orderObject.get('items').errors?.breakfastItemError">
            ERROR:
            You have breakfast items in your cart. Please remove the items or change the order time. </p>
        </div>
        <!-- TO DO: SET DYNAMIC LUNCH TIME -->
        <div>
          <h3 class="text-center mt-2">Checkout</h3>

          <div *ngIf="!(afAuth.user | async) else loggedIn">
            <p class="text-center mt-2 mb-2">Sign in to save contact info for next time or select "Guest Checkout."
            </p>
            <app-login></app-login>
          </div>
        </div>
        <ng-template #loggedIn>
          <form (ngSubmit)="openPaymentDialog($event)" [formGroup]="orderService.orderObject" #addForm="ngForm">
            <p class="upperHint">Contact Info. We will contact you if there are any issues. </p>
            <div class="userInfo">
              <mat-form-field class="w-50" appearance="fill" required="true">
                <mat-label>First</mat-label>
                <input formControlName="first" type="text" matInput placeholder="First Name" autocomplete="given-name">
                <mat-error>Required</mat-error>
              </mat-form-field>
              <mat-form-field class="w-50" appearance="fill" required="true">
                <mat-label>Last</mat-label>
                <input formControlName="last" type="text" matInput placeholder="Last Name" autocomplete="family-name">
                <mat-error>Required</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100" appearance="fill" required="true">
                <mat-label>Email</mat-label>
                <input formControlName="email" type="email" matInput placeholder="Email" autocomplete="email">
                <mat-error>Required</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100" appearance="fill" required="true">
                <mat-label>Phone Number</mat-label>
                <input formControlName="phoneNum" type="tel" matInput placeholder="3335559999"
                  autocomplete="tel-national">
                <mat-error>Required</mat-error>
              </mat-form-field>

            </div>


            <mat-divider></mat-divider>
            <p class="text-danger"
              *ngIf="orderService.orderObject.get('subTotal').invalid && orderService.restaurant.deliveryMinSubtotal">
              Your
              subtotal must be at least
              $5 for pickup orders and ${{orderService.restaurant.deliveryMinSubtotal}} for delivery. </p>
            <p class="text-danger"
              *ngIf="orderService.orderObject.get('subTotal').invalid && !orderService.restaurant.deliveryMinSubtotal">
              Your
              subtotal must be at least
              $5 for pickup orders and $15 for delivery. </p>


            <p class="edit text-danger m-1" (click)="openTimeDetails()"
              *ngIf="orderService.orderObject.errors?.invalidOrderTime">You have not selected
              a valid order time
            </p>

            <div *ngIf="orderService.appliedCoupons.length > 0" class="flex flex-c flex-wrap">
              <div *ngFor="let coupon of orderService.appliedCoupons.controls let index = i">
                <div *ngIf="coupon.errors">
                  <div class="flex-wrap space-between">
                    <p *ngIf="coupon.errors?.minSubtotal" class="text-danger">{{coupon.value.title}}:
                      {{coupon.errors.minSubtotal}}</p>
                    <p *ngIf="coupon.errors?.orderType" class="text-danger">{{coupon.value.title}}:
                      {{coupon.errors.orderType}}</p>
                    <p *ngIf="coupon.errors?.mixable" class="text-danger">{{coupon.value.title}}:
                      {{coupon.errors.mixable}}</p>
                    <button [routerLink]="orderService.restaurant.restaurantID" mat-stroked-button class="m_5"
                      color="primary">
                      Add Items
                    </button>
                    <button class="m-l-8" mat-stroked-button color="warn"
                      (click)="orderService.removeCoupon(coupon)">Remove
                    </button>
                  </div>
                </div>


              </div>
            </div>


            <mat-spinner class="spinner" *ngIf="this.loading"></mat-spinner>
            <button type='submit' mat-raised-button class="w-100" color="primary">Make Payment</button>
          </form>
        </ng-template>
      </section>
      <ng-template #noItems>
        <p class="text-center mb-3">No items in cart. 😩</p>
        <button mat-raised-button routerLink="../" class="w-100 mt-4" color="accent">View Menu </button>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
<!-- <mat-card class="mt-3">
  <pre>{{orderService.orderObject.value | json}} </pre>
</mat-card> -->